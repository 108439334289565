import Immutable from 'seamless-immutable';
import {
  AUTO_CONNECT_IFRAME,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILED,
  SET_USER_ACCESS_TOKEN,
  SET_USER_AGENT,
  FETCH_CORRECTION_AI_ACCESS_SUCCESS
} from '../actions';

export const INITIAL_STATE = Immutable({
  username: 'test_',
  error: false
});

const user = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
  case AUTO_CONNECT_IFRAME: {
    const {
      accessToken,
      userAgent
    } = payload;
    return {
      ...state,
      ...accessToken && { accessToken },
      ...userAgent && { agent: userAgent }
    };
  }
  case USER_LOGIN_SUCCESS:
    state = { ...state, ...payload, error: false };
    return state;
  case USER_LOGIN_FAILED:
    state = { ...state, error: true };
    return state;
  case SET_USER_ACCESS_TOKEN:
    return { ...state, accessToken: payload.accessToken };
  case SET_USER_AGENT:
    return { ...state, agent: payload.agent };
  case FETCH_CORRECTION_AI_ACCESS_SUCCESS:
    state = {
      ...state,
      correctionAiAccess: payload
    };
    return state;
  default:
    return state;
  }
};

export default user;
