export const ADD_ENTITIES = 'add.entities';

export const AUTO_CONNECT_IFRAME = 'auto.connect.iframe';

export const USER_LOGIN_SUCCESS = 'user.login.success';

export const SAVE_NAVIGATION_BEFORE_MODULE = 'save.navigation.before.module';
export const GO_TO_BEFORE_MODULE = 'go.to.before.module';

export const FETCH_DATA = 'fetch.data';
export const FETCH_DATA_SUCCESS = 'fetch.data.success';
export const FETCH_DATA_FAIL = 'fetch.data.fail';

export const FETCH_MODULES = 'fetch.modules';
export const FETCH_MODULES_SUCCESS = 'fetch.modules.success';
export const FETCH_MODULES_FAIL = 'fetch.modules.fail';

export const END_MODULE = 'end.module';
export const END_MODULE_SUCCESS = 'end.module.success';

export const FETCH_ACTIVITIES = 'fetch.activities';
export const FETCH_ACTIVITIES_SUCCESS = 'fetch.activities.success';
export const FETCH_ACTIVITIES_FAIL = 'fetch.activities.fail';

export const FETCH_ACTIVITIES_BY_ID = 'fetch.activity.by.id';
export const FETCH_ACTIVITIES_BY_ID_SUCCESS = 'fetch.activity.by.id.success';
export const FETCH_ACTIVITIES_BY_ID_FAIL = 'fetch.activity.by.id.fail';

export const FETCH_MODULE_WITH_ACTIVITIES = 'fetch.module.with.activities';
export const FETCH_MODULE_WITH_ACTIVITIES_SUCCESS = 'fetch.module.with.success';
export const FETCH_MODULE_WITH_ACTIVITIES_FAIL = 'fetch.module.with.fail';

export const FETCH_SURVEY = 'fetch.survey';
export const FETCH_SURVEY_SUCCESS = 'fetch.survey.success';
export const FETCH_SURVEY_FAIL = 'fetch.survey.fail';

export const NAVIGATE = 'navigate';
export const NAVIGATE_SUCCESS = 'navigate.success';
export const NAVIGATE_FAIL = 'navigate.fail';

export const SEND_RESPONSE = 'send.response';
export const SEND_RESPONSE_SUCCESS = 'send.response.success';
export const SEND_RESPONSE_FAIL = 'send.response.fail';

export const SEND_RESPONSE_WITH_FILE = 'send.response.with.file';
export const SEND_RESPONSE_WITH_FILE_SUCCESS = 'send.response.with.file.success';
export const SEND_RESPONSE_WITH_FILE_FAIL = 'send.response.with.file.fail';

export const GO_TO_PREVIOUS_ACTIVITY = 'goto.previous.activity';
export const GO_TO_NEXT_ACTIVITY = 'goto.next.activity';
export const GO_TO_FIRST_ACTIVITY = 'goto.first.activity';

export const RESET_SURVEY_AND_GO_TO_FIRST_ACTIVITY = 'reset.survey.and.goto.first.activity';
export const RESET_SURVEY_AND_GO_TO_FIRST_ACTIVITY_SUCCESS = 'reset.survey.and.goto.first.activity';

export const SEE_CORRECTION_MODULE = 'see.correction.module';
export const SEE_CORRECTION_MODULE_SUCCESS = 'see.correction.module.success';

export const GO_TO_START_MODULE = 'goto.start.module';
export const GO_TO_CUSTOM_END = 'goto.custom.end';
export const GO_TO_END_MODULE = 'goto.end.module';
export const LEAVE_MODULE = 'leave.module';
export const CUSTOM_LEAVE_MODULE = 'custom.leave.module';

export const PLAY_SOUND = 'play.sound';
export const PLAY_SOUND_SUCCESS = 'play.sound.success';
export const PLAY_SOUND_ERROR = 'play.sound.error';
export const PAUSE_SOUND = 'pause.sound';
export const PAUSE_SOUND_SUCCESS = 'pause.sound.success';
export const LOAD_SOUND = 'load.sound';
export const LOAD_SOUND_SUCCESS = 'load.sound.success';
export const UNLOAD_SOUND = 'unload.sound';
export const UNLOAD_SOUND_SUCCESS = 'unload.sound.success';

export const RECORD_SOUND_START = 'record.sound.start';
export const RECORD_SOUND_START_SUCCESS = 'record.sound.start.success';
export const RECORD_SOUND_END = 'record.sound.end';
export const RECORD_SOUND_END_SUCCESS = 'record.sound.end.success';

export const CURRENT_PERMISSION_AUDIO_RECORDING = 'current.permission.audio.recording';
export const ASK_PERMISSION_FOR_AUDIO_RECORDING = 'ask.permission.for.audio.recording';
export const ASK_PERMISSION_FOR_AUDIO_RECORDING_SUCCESS = 'ask.permission.for.audio.recording.success';

export const SET_CURRENT_MODULE = 'set.current.module';
export const SET_CURRENT_ACTIVITY_INDEX = 'set.current.activity.index';
export const SET_CURRENT_LOCALE = 'set.current.locale';
export const SET_CURRENT_LOCAL_SUCCESS = 'set.current.locale.success';
export const SET_USER_ACCESS_TOKEN = 'set.user.accessToken';
export const SET_USER_AGENT = 'set.user.agent';

export const SET_SURVEY_IS_LOADED_FALSE = 'set.survey.is.loaded.false';
export const SET_SURVEY_IS_LOADED_TRUE = 'set.survey.is.loaded.true';

export const CMI5_RETRIEVE_LMS_LAUNCH_DATA = 'cmi5.retrieve.lms.launch.data';
export const CMI5_SEND_STATEMENT_INITIALIZED = 'cmi5.send.statement.initialized';

export const CMI5_END_XAPI_SEQUENCE = 'cmi5.end.xapi.sequence';

export const SET_RESUME_TRUE = 'set.resume.true';

export const SET_TRANSITION_MODE_TRUE = 'set.transition.mode.true';

export const SET_MODULE_RESOLUTION_MODE = 'set.module.resolution.mode';

// Loading gestion
export const SET_LOADING = 'set.loading';
export const SET_LOADED = 'set.loaded';

export const SET_IMPERSONATED = 'set.impersonated';

export const SET_ACTIVE_DROPZONE = 'set.active.dropzone';

export const SET_CURRENT_CLASSROOM_ID = 'set.current.classroom.id';

export const GO_TO_FIRST_ACTIVITY_NOT_DONE = 'go.to.first.activity.not.done';

export const SET_CURRENT_SECTION = 'set.current.section';
export const GO_TO_SECTION_TRANSITION = 'goto.section.transition';

export const FETCH_TRANSITION = 'fetch.transition';
export const FETCH_TRANSITION_SUCCESS = 'fetch.transition.success';
export const FETCH_TRANSITION_FAIL = 'fetch.transition.fail';

export const TRANSITION_CONTINUE = 'transition.continue';

export const SET_SHOW_BEFORE_LEAVE_WARNING_TRUE = 'before.leave.warning.true';
export const SET_SHOW_BEFORE_LEAVE_WARNING_FALSE = 'before.leave.warning.false';

export const FETCH_SECTION = 'fetch.sections';
export const FETCH_SECTION_SUCCESS = 'fetch.sections.success';
export const FETCH_SECTION_FAIL = 'fetch.sections.fail';

export const SET_CORRECTION_MODE_ONLY = 'set.correction.mode.only';

export const RESET_ACTIVITY_TIME = 'reset.activity.time';
export const INCREMENT_ACTIVITY_TIME = 'increment.activity.time';

export const LEARNER_BUG_REPORT = 'learner.bug.report';

export const SEND_RESPONSE_FOR_AUTO_CORRECTION = 'send.response.for.auto.correction';

export const FETCH_CORRECTION_AI_ACCESS = 'fetch.correction.ai.access';
export const FETCH_CORRECTION_AI_ACCESS_SUCCESS = 'fetch.correction.ai.access.success';
