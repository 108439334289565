// TODO: use reselect if it is slow
import { createSelector } from 'reselect';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import findIndex from 'lodash/findIndex';
import shuffle from 'lodash/shuffle';
import { ACTIVITY_PROPERTIES_NAMES } from '../constants/api';

// pure selectors
export const getUser = state => state.user;
export const getAccessToken = state => state.user.accessToken;
export const getAuthData = state => ({
  accessToken: state.user.accessToken,
  userAgent: state.user.agent
});
export const hasErrorJoinClassroom = state => state.ui.joinClassroomError;
export const hasErrorAuth = state => state.user.error;
export const getCurrentNBactivities = (state) => (state.models.activities || []).length;
export const getCurrentActivity = (state, currentActivity) => get(state, ['models', 'activities', currentActivity], {});
export const getCurrentActivityPoint = (state, currentActivity) => get(state, ['models', 'activities', currentActivity, 'bloc_question', 'point']);
export const getActivities = state => state.models.activities;
export const getSurvey = state => state.models.survey;
export const getCurrentSurveyId = state => state.models.survey.id;
export const getCurrentSurveyStatus = state => state.models.survey.status;
export const getClassrooms = state => state.models.classrooms;
export const getPrograms = state => state.models.programs;
export const getBackBeforeModule = state => state.ui.backBeforeModule;
export const getCurrentModuleId = state => get(state, 'ui.currentModule.moduleId', -1);
export const getCurrentEvaluationSessionId = state => get(state, 'ui.currentEvaluationSession.evaluationSessionId', -1);
export const getCurrentLocale = state => state.ui.currentLocale;
export const getAppIsReady = state => state.ui.appIsReady;
export const getSurveyIsLoaded = state => state.ui.surveyIsLoaded;
export const getHomeworks = state => state.models.homeworks;
export const getRecommendations = state => state.models.recommendations;
export const getSound = (state, src) => state.audio.sounds[src] ? state.audio.sounds[src] : {};
export const getCurrentSoundPlaying = state => state.audio.current;
export const getCurrentRecord = state => state.audio.record;
export const getModules = state => state.models.modules;
export const getHaveRecordingPermissions = state => {
  return state.audio.record.haveRecordingPermissions;
};

export const getCmi5Info = state => {
  const { endpoint, 'auth-token': auth, actor, registration, activityId } = state.user;
  const isCMI5Inter = endpoint && auth && actor && registration && !!activityId;
  return {
    ...state.user,
    isCMI5Inter
  };
};

export const getIsFrelloEmployee = (state) => {
  const userEmail = state.user.email;
  return userEmail?.endsWith('frello.fr');
};

export const getUserHasNoAccountYet = (state) => {
  const userEmail = state.user.email;
  return userEmail?.endsWith('noaccount.fr');
};

export const getCurrentUserEmail = (state) => {
  const userEmail = state.user.email;
  return userEmail;
};

export const selectIsIndependant = (state) => {
  return state.user?.isIndependent;
};

export const getLtiInfo = state => {
  const { lti } = state.user;
  return {
    ...state.user,
    isLti: lti
  };
};

export const getModulePoints = state => {
  const activities = getActivities(state);
  return activities.reduce(
    (mem, activity) => mem + get(activity, ['bloc_question', 'point'], 0)
    , 0
  );
};

export const getQuestions = createSelector(
  [getCurrentActivity],
  (activity) => {
    const type = getTypeFromActivity(activity);
    const {
      questionsBlockName
    } = ACTIVITY_PROPERTIES_NAMES[type];
    return activity[questionsBlockName];
  }
);

export const getRadomReponses = createSelector(
  [getCurrentActivity],
  (activity) => {
    const type = getTypeFromActivity(activity);
    const {
      questionsBlockName
    } = ACTIVITY_PROPERTIES_NAMES[type];
    return shuffle(activity[questionsBlockName]);
  }
);

export const getResponses = createSelector(
  [getCurrentActivity],
  (activity) => {
    const type = getTypeFromActivity(activity);
    const {
      questionsBlockName
    } = ACTIVITY_PROPERTIES_NAMES[type];
    return activity[questionsBlockName];
  }
);

// memoise selectors
export const getTypeFromActivity = (activity) => {
  if (activity.bloc_question) {
    return get(activity, 'bloc_question.bloc.type', 'unknow');
  } else if (activity.questionBlock) {
    return get(activity, 'questionBlock.type', 'unknow');
  } else {
    return get(activity, 'bloc.type', 'unknow');
  }
};

export const getImageUrlFrom = (additionnel_image) => {
  if (additionnel_image && additionnel_image.media_file) {
    return additionnel_image.media_file.fullUrl;
  }
  return null;
};

export const getAdditonalAudioUrlFrom = (activity) => {
  return (activity.bloc_question || activity).bloc.fullAdditionalAudioUrl;
};

export const modulePictos = {
  'comprehension-orale': '/static/img/picto/module-oral-comprehension.png',
  lexique: '/static/img/picto/module-lexicon.png',
  grammaire: '/static/img/picto/module-grammar.png',
  phonetique: '/static/img/picto/module-phonological-phonetic.png',
  'expression-ecrite': '/static/img/picto/module-written-expression.png',
  'expression-orale': '/static/img/picto/module-oral-expression.png',
  'comprehension-ecrite': '/static/img/picto/module-written-comprehension.png',
  graphemes: '/static/img/picto/module-graphemes.png',
  reading: '/static/img/picto/reading.png',
  'soft-skill': '/static/img/picto/soft-skill.png',
  numeric: '/static/img/picto/numeric.png',
  numeracy: '/static/img/picto/numeracy.png',
  positioning: '/static/img/picto/positioning.png'
};

export const modulePictosX3 = {
  'comprehension-orale': '/static/img/picto/module-oral-comprehension@3x.png',
  lexique: '/static/img/picto/module-lexicon@3x.png',
  grammaire: '/static/img/picto/module-grammar@3x.png',
  phonetique: '/static/img/picto/module-phonological-phonetic@3x.png',
  'expression-ecrite': '/static/img/picto/module-written-expression@3x.png',
  'expression-orale': '/static/img/picto/module-oral-expression@3x.png',
  'comprehension-ecrite': '/static/img/picto/module-written-comprehension@3x.png',
  graphemes: '/static/img/picto/module-graphemes@3x.png',
  reading: '/static/img/picto/reading.png',
  'soft-skill': '/static/img/picto/soft-skill.png',
  numeric: '/static/img/picto/numeric.png',
  numeracy: '/static/img/picto/numeracy.png',
  positioning: '/static/img/picto/positioning.png'
};

export const moduleBluePictos = {
  'comprehension-orale': '/static/img/picto/comprehension_orale.png',
  lexique: '/static/img/picto/lexique.png',
  grammaire: '/static/img/picto/grammaire.png',
  phonetique: '/static/img/picto/phonetico-phonologie.png',
  'expression-ecrite': '/static/img/picto/expression_ecrite.png',
  'expression-orale': '/static/img/picto/expression_orale.png',
  'comprehension-ecrite': '/static/img/picto/comprehension_ecrite.png',
  graphemes: '/static/img/picto/graphemes.png',
  reading: '/static/img/picto/reading.png',
  'soft-skill': '/static/img/picto/soft-skill.png',
  numeric: '/static/img/picto/numeric.png',
  numeracy: '/static/img/picto/numeracy.png',
  positioning: '/static/img/picto/positioning.png'
};

export const getBluePictoTypeFromModule = (module) => {
  if (!module) {
    return null;
  }
  const {
    skillType
  } = module;

  if (skillType) {
    return moduleBluePictos[skillType.pictogram];
  }

  // todo: what is the default picture
  return moduleBluePictos['comprehension-ecrite'];
};

export const getPictoTypeFromModule = (module) => {
  if (!module) {
    return null;
  }
  const {
    skillType
  } = module;

  if (skillType) {
    return modulePictosX3[skillType.pictogram];
  }
  // todo: what is the default picture
  return modulePictosX3['comprehension-ecrite'];
};

export const getStatutFromModule = (module) => {
  if (!module || !module.questionnaires || module.questionnaires.length === 0) {
    return false;
  } else {
    return module.questionnaires[0].termine === 1;
  }
};
export const getInformationFromModule = (state, module) => {
  let name = '';
  let description = '';

  if ('type' in state.section && state.section.type !== 'positioning') {
    name = state.section.lesson?.objective?.name || state.section.lesson?.communicationSkill?.name;
    description = state.section.lesson?.objective?.description || state.section.lesson?.communicationSkill?.description;
  }

  if (!module) {
    return {};
  }

  const {
    skillType,
    skill,
    lecons
  } = module;

  if (skillType?.category === 'base') {
    name = skillType?.name;
    description = skill?.description;
  }

  if (skillType?.category === 'communication') {
    name = skillType?.name;
    description = lecons[0]?.objective?.description;
  }

  return {
    name,
    description,
    isTerminated: getStatutFromModule(module)
  };
};

export const getModulesFromRecommendations = (recommendations = []) => {
  return recommendations && recommendations.map(reco => reco.module);
};

export const getModulesRecommendations = (state) => {
  const recommendations = getRecommendations(state);
  return getModulesFromRecommendations(recommendations);
};

// todo: refactor with normalize
export const getModuleById = (state, moduleId) => {
  const module = state.models.modules.find(mod => parseInt(mod.id, 10) === parseInt(moduleId, 10));
  return module;
};

export const getHomeworksGroupeByClassromId = createSelector(
  [getClassrooms, getHomeworks],
  (classrooms, homeworks) => {
    const homeworkByClassroom = groupBy(homeworks, 'classe_id');
    const classroomsWithDevoirs = reduce(Object.keys(homeworkByClassroom),
      (acc, classId) => {
        const modules = getModuleFromHomeworks(homeworkByClassroom[classId]);
        if (!modules || modules.length === 0) {
          return acc;
        }
        return [...acc, { nom: homeworkByClassroom[classId][0].classe.nom, modules }];
      }
      , []);
    return classroomsWithDevoirs;
  }
);

const showModule = (module) => {
  if (module.active === 0) return false;
  if (!module.questionnaires || module.questionnaires.length === 0) return true;
  return module.questionnaires[0].termine === 0;
};

export const getModuleFromHomeworks = (homeworks) => {
  return homeworks
    .reduce((acc, homework) => [...acc, ...homework.modules], [])
    .filter(showModule)
    .reduce((acc, module) => {
      if (acc.some(element => element.id === module.id)) {
        return acc;
      }
      return [...acc, module];
    }, []);
};

/**
  idealy this function is useless if we use normalizer
  */
export const getModulesByLessonId = createSelector([getModules, (...args) => args[1]], (modules, lessonId) => {
  return modules
    .filter(m => {
      if (!m.lecons || m.lecons.length === 0) return false;
      return m.lecons.findIndex(l => parseInt(l.id, 10) === parseInt(lessonId, 10)) > -1;
    });
});

export const getModulesFromPrograms = (programs) => {
  const modules = programs.reduce((acc, value) => {
    if (value.unites && value.unites.length > 0) {
      return value.unites.reduce(
        (acc2, value2) => {
          if (value2.lecons && value2.lecons.length > 0) {
            return value2.lecons.reduce(
              (acc3, value3) => {
                if (value3.modules && value3.modules.length > 0) {
                  return [...acc3, ...value3.modules];
                }
                return acc3;
              }, acc2);
          }
          return acc2;
        }
        , acc);
    }
    return acc;
  }, []);

  return modules;
};

export const getCurrentResponseByActivityId = (state, activityId) => {
  const responses = get(state, 'models.survey.reponses', []);
  const response = responses.find(rep => parseInt(rep?.reponse?.bloc_id || rep?.response?.blockId, 10) === parseInt(activityId, 10));
  return response || {};
};

const sortResponses = (responses) => {
  return responses.sort((r1, r2) => r1.reponse.ordre - r2.reponse.ordre);
};
export const getFirstActivityNotDone = (state) => {
  // If survey does not exist
  if (state.models.survey?.reponses === undefined) {
    return 0;
  }

  const sortedResponses = sortResponses(state.models.survey.reponses);

  const reponse = sortedResponses.find(({ reponse }) => reponse.repondu === 0);

  if (!reponse) return state.models.survey.reponses.length;

  const activityIndex = state.models.activities
    .findIndex(activity => (activity.bloc || activity.bloc_question || activity.questionBlock).id === reponse.reponse.bloc_id);

  return activityIndex > -1 ? activityIndex : 0;
};

export const updateSurveyInModule = (modules, survey) => {
  const index = findIndex(modules, { id: survey.module_id });
  const { apprenant_id, id, module_id, score, termine } = survey;
  const modulesUpdated = [...modules].splice(index, 1,
    {
      ...modules[index],
      questionnaires: [{
        apprenant_id,
        id,
        module_id,
        score,
        termine
      }]
    }
  );
  return modulesUpdated;
};

const buildDescription = (response, type) => {
  const { answerName } = ACTIVITY_PROPERTIES_NAMES[type];
  switch (type) {
  case 'blocquestionassociationimagetexte':
    return `<br><img src="${get(response, [answerName, 'media_file', 'fullUrl'], '')}" style="width: 300px;" class="fr-fic fr-dib">`;
  case 'blocquestionassociationaudiotexte':
    return `<span class="fr-audio fr-dvb fr-draggable" contenteditable="false" draggable="true">
    <audio class="fr-draggable" 
    controls="" src="${get(response, [answerName, 'fullAudioGaucheUrl'], '')}" style="width: 600px;">Votre navigateur ne supporte pas les audios en format HTML5.</audio></span>`;
  case 'blocquestionassociationtextetexte':
    return get(response, [answerName, 'texte_gauche'], '');
  default:
    return '';
  }
};
const getFormatCMI5 = (response, type) => {
  /**
    todo: à remplacer par l'audio quand il sera disponible
  **/
  if (type === 'blocenregistrementaudio') {
    return {
      learnerResponse: `<span class="fr-audio fr-dvb fr-draggable" contenteditable="false" draggable="true">
    <audio class="fr-draggable" 
    controls="" src="${response.learner_response}" style="width: 600px;">Votre navigateur ne supporte pas les audios en format HTML5.</audio></span>`
    };
  }
  const { answersBlockName, answerName, interactionType } = ACTIVITY_PROPERTIES_NAMES[type];
  if (interactionType === 'matching') {
    const correctResponsesPattern = response[answersBlockName]
      .map((response) => {
        return response.id + '[.]' + response[answerName].id;
      }).join('[,]');

    const learnerResponse = response[answersBlockName]
      .map((response) => {
        return response.id + '[.]' + response.learner_response.id;
      }).join('[,]');

    const target = response[answersBlockName]
      .map((response) => {
        return {
          description: {
            'en-US': `<div>${buildDescription(response, type)}</div>`
          },
          id: `${response.id}`
        };
      });

    const source = response[answersBlockName]
      .map((response) => {
        return {
          description: {
            'en-US': `${response[answerName].texte_droite}`
          },
          id: `${response[answerName].id}`
        };
      });

    return {
      correctResponsesPattern,
      learnerResponse,
      target,
      source
    };
  }

  if (interactionType === 'fill-in') {
    const correctResponsesPattern = response[answersBlockName].map(
      response => {
        switch (type) {
        case 'blocquestiontrouinput':
          return get(response, [answerName, 'bloc_question_trou_select_reponse_options', '0', 'opt'], '');
        case 'blocquestiontrouselect':
          return get(get(response, [answerName, 'bloc_question_trou_select_reponse_options'], [])
            .find(option => option?.correcte === 1), ['opt'], null);
        case 'blocquestiontroutexte':
          return response[answerName].texte_trou;
        default:
          return null;
        }
      }).filter(r => !!r).join('[,]');

    const learnerResponse = response[answersBlockName].map(
      response => {
        switch (type) {
        case 'blocquestiontrouinput':
          return get(response, ['reponse'], null);
        case 'blocquestiontrouselect':
          return get(response, ['bloc_question_trou_select_reponse_option', 'opt'], null);
        case 'blocquestiontroutexte':
          return response.learner_response.texte_trou;
        default:
          return null;
        }
      }).filter(r => !!r).join('[,]');

    const description = response[answersBlockName].map(response => {
      const { texte_fermant, texte_ouvrant } = response[answerName];
      return `${texte_ouvrant || ''} &nbsp ${texte_fermant || ''}`;
    }).join(' <br/> ');

    return {
      correctResponsesPattern,
      learnerResponse,
      description: {
        'en-US': description
      }
    };
  }

  if (interactionType === 'choice') {
    const correctResponsesPattern = response[answersBlockName]
      .reduce((mem, response) => {
        if (response[answerName]?.correcte === 1) {
          return [...mem, response.id];
        }
        return mem;
      }, []).join('[,]');
    const learnerResponse = response[answersBlockName]
      .reduce((mem, response) => {
        if (response.reponse === 1) {
          return [...mem, response.id];
        }
        return mem;
      }, []).join('[,]');

    const choices = response[answersBlockName].map(
      response => {
        return {
          description: {
            'en-US': response[answerName].reponse
          },
          id: `${response.id}`
        };
      }
    );
    return {
      correctResponsesPattern,
      learnerResponse,
      choices
    };
  }

  if (interactionType === 'long-fill-in') {
    return {
      learnerResponse: response.reponse_txt
    };
  }
};

export const getCmi5Activity = (state, activityId, result) => {
  const activity = getCurrentActivity(state, activityId);
  const type = getTypeFromActivity(activity);
  const temp = getCurrentResponseByActivityId(state, activity.id);
  if (!temp || Object.keys(temp).length === 0) {
    return {};
  }
  if (!Object.prototype.hasOwnProperty.call(ACTIVITY_PROPERTIES_NAMES, type)) {
    return false;
  }

  const { interactionType } = ACTIVITY_PROPERTIES_NAMES[type];
  const question = get(activity, 'bloc_question.question', false) || get(activity, 'titre', '');
  const descriptionStatic = get(activity, 'bloc.additionnel_texte', false);

  const {
    correctResponsesPattern,
    learnerResponse,
    target,
    source,
    description: descriptionDynamic,
    choices
  } = getFormatCMI5(temp, type);
  const name = {
    'en-US': question
  };

  const description = descriptionDynamic || (descriptionStatic
    ? { 'en-US': descriptionStatic }
    : name);

  return {
    result: {
      ...result,
      response: learnerResponse
    },
    activityCMI5: {
      definition: {
        choices,
        target,
        source,
        interactionType,
        description: description || name,
        type: 'http://adlnet.gov/expapi/activities/cmi.interaction',
        name,
        correctResponsesPattern: [correctResponsesPattern || '']
      }
    }
  };
};

// selector for loading
export const isLoading = state => {
  return state.ui.loading.length > 0;
};

export const addOrderToResults = (activity, result) => {
  const type = getTypeFromActivity(activity);
  if (type === 'blocquestionassociationimageaudio' || type === 'blocquestionassociationaudioaudio') {
    const { questionsBlockName, answersBlockName, answerName } = ACTIVITY_PROPERTIES_NAMES[type];
    activity[questionsBlockName].forEach(activityResponse => {
      result[0][answersBlockName].forEach(e => {
        if (e.learner_response.id === activityResponse.id) {
          e.learner_response.order = activityResponse.order;
        };
        if (e[answerName].id === activityResponse.id) {
          e[answerName].order = activityResponse.order;
        };
      });
    });
  }
};

export const addOrderToSurveyResponses = (activities, survey) => {
  activities.forEach(activity => {
    const type = getTypeFromActivity(activity);
    if (type === 'blocquestionassociationimageaudio' || type === 'blocquestionassociationaudioaudio') {
      const { questionsBlockName, answersBlockName, answerName } = ACTIVITY_PROPERTIES_NAMES[type];
      activity[questionsBlockName].forEach(activityResponse => {
        survey.reponses.forEach(response => {
          answersBlockName in response && response[answersBlockName].forEach(e => {
            if (e.learner_response.id === activityResponse.id) {
              e.learner_response.order = activityResponse.order;
            };
            if (e[answerName].id === activityResponse.id) {
              e[answerName].order = activityResponse.order;
            };
          });
        });
      });
    }
  });
};
export const audioIsLoading = state => state.audio.loading;
export const selectActiveDropzone = state => state.ui.activeDropzone;
export const getModuleCompletionMode = state => state.ui.moduleCompletionMode;

export const getModuleResolutionData = state => ({
  tabId: state.ui.tabId,
  extension: state.ui.moduleCompletionMode,
  sectionId: state.ui.currentSection?.sectionId,
  evaluationSessionId: state.ui.currentEvaluationSession?.evaluationSessionId,
  moduleId: state.ui.ccurrentModule?.moduleId,
  moduleVersionId: state.ui.currentModuleVersion?.moduleVersionId,
  activityIndex: state.ui.currentActivityIndex,
  classroomId: state.ui.currentClassroomId,
  surveyId: state.models.survey?.id,
  redirectURI: state.ui.redirectURI,
  impersonates: state.ui.impersonates
});

export const getCorrectResponse = (state, blockId) => {
  return state.models?.survey?.defaultResponses?.find(e => e.blockId === blockId)?.corrige_reponse;
};

export const getCorrectResponseTeacherName = (state, blockId) => {
  return state.models?.survey?.defaultResponses?.find(e =>
    e.blockId === blockId)?.professeurs[0]?.user?.nom || '';
};

export const selectCorrectionAiAccess = (state) => state.user.correctionAiAccess;
export const selectCurrentClassroomId = state => state.ui.currentClassroomId;
