import getConfig from 'next/config';

export const URL_API_FRELLO = getConfig().publicRuntimeConfig.apiUrl;
export const MAIN_URL_FRELLO = getConfig().publicRuntimeConfig.mainUrl;
export const TRANSITIONS_MS_URL = getConfig().publicRuntimeConfig.transitionsMSUrl;
export const CONTENTS_MS_URL = getConfig().publicRuntimeConfig.contentsMSUrl;
export const SURVEYS_MS_URL = getConfig().publicRuntimeConfig.surveysMSUrl;
export const CORRECTIONS_MS_URL = getConfig().publicRuntimeConfig.correctionsMSUrl;
export const ACCOUNTS_MS_URL = getConfig().publicRuntimeConfig.accountsMSUrl;
export const SCHOOLS_MS_URL = getConfig().publicRuntimeConfig.schoolsMSUrl;
export const MIXPANEL_TOKEN = getConfig().publicRuntimeConfig.mixpanelToken;
export const ACCOUNT_SERVICE_URL = getConfig().publicRuntimeConfig.accountServiceUrl;

export const ACTIVITY_PROPERTIES_NAMES = {
  blocquestionassociationtextetexte: {
    blockName: 'bloc_association_texte_texte',
    questionsBlockName: 'bloc_question_association_texte_texte_reponses',
    answersBlockName: 'reponse_question_association_texte_texte_reponses',
    answerName: 'bloc_question_association_texte_texte_reponse',
    questionName: 'bloc_question_association_texte_texte_question',
    answerIdName: 'bloc_question_association_texte_texte_reponse_id',
    associatedAnswerIdName: 'bloc_question_association_texte_texte_reponse_correspondante_id',
    interactionType: 'matching'
  },
  blocquestionassociationimagetexte: {
    blockName: 'bloc_question',
    questionsBlockName: 'bloc_question_association_image_texte_reponses',
    answersBlockName: 'reponse_question_association_image_texte_reponses',
    answerName: 'bloc_question_association_image_texte_reponse',
    questionName: 'bloc_question_association_image_texte_image_url',
    answerIdName: 'bloc_question_association_image_texte_reponse_id',
    associatedAnswerIdName: 'bloc_question_association_image_texte_reponse_correspondante_id',
    interactionType: 'matching'
  },
  blocquestionassociationaudiotexte: {
    blockName: 'bloc_association_audio_texte',
    questionsBlockName: 'bloc_question_association_audio_texte_reponses',
    answersBlockName: 'reponse_question_association_audio_texte_reponses',
    answerName: 'bloc_question_association_audio_texte_reponse',
    questionName: 'bloc_question_association_audio_texte_audio_url',
    answerIdName: 'bloc_question_association_audio_texte_reponse_id',
    associatedAnswerIdName: 'bloc_question_association_audio_texte_reponse_correspondante_id',
    interactionType: 'matching'
  },
  blocquestiontroutexte: {
    blockName: 'bloc_association_texte_texte',
    questionsBlockName: 'bloc_question_trou_texte_reponses',
    answersBlockName: 'reponse_question_trou_texte_reponses',
    answerName: 'bloc_question_trou_texte_reponse',
    questionName: 'texte_ouvrant',
    questionClosingTextName: 'texte_fermant',
    answerIdName: 'bloc_question_trou_texte_reponse_id',
    associatedAnswerIdName: 'bloc_question_trou_texte_reponse_correspondante_id',
    interactionType: 'fill-in'
  },
  blocquestiontrouselect: {
    blockName: 'bloc_question_trou_select',
    questionsBlockName: 'bloc_question_trou_select_reponses',
    answersBlockName: 'reponse_question_trou_select_reponses',
    answerName: 'bloc_question_trou_select_reponse',
    questionName: 'texte_ouvrant',
    questionClosingTextName: 'texte_fermant',
    answerIdName: 'bloc__question_trou_select_reponses_reponse_id',
    associatedAnswerIdName: 'bloc__question_trou_select_reponses_reponse_correspondante_id',
    opts: 'bloc_question_trou_select_reponse_options',
    interactionType: 'fill-in'
  },
  blocquestiontrouinput: {
    interactionType: 'fill-in',
    answersBlockName: 'reponse_question_trou_input_reponses',
    answerIdName: 'bloc_question_trou_input_reponse_id',
    answerName: 'bloc_question_trou_input_reponse'
  },
  blocquestionchoixmultiple: {
    interactionType: 'choice',
    answersBlockName: 'reponse_question_choix_multiple_reponses',
    answerIdName: 'bloc_question_choix_multiple_reponse_id',
    answerName: 'bloc_question_choix_multiple_reponse'
  },
  blocquestionouverte: {
    interactionType: 'long-fill-in',
    answersBlockName: 'reponse_question_ouverte_reponses',
    answerIdName: 'bloc_question_ouverte_reponse_id',
    answerName: 'reponse'
  },
  blocenregistrementaudio: {
    interactionType: 'long-fill-in',
    answersBlockName: 'reponse',
    answerIdName: 'bloc_enregistrement_audio_reponse_id'
  },
  blocquestionassociationimageaudio: {
    blockName: 'bloc_association_image_audio',
    questionsBlockName: 'bloc_question_association_image_audio_reponses',
    answersBlockName: 'reponse_question_association_image_audio_reponses',
    answerName: 'bloc_question_association_image_audio_reponse',
    questionName: 'bloc_question_association_image_audio_audio_url',
    answerIdName: 'bloc_question_association_image_audio_reponse_id',
    associatedAnswerIdName: 'bloc_question_association_image_audio_reponse_correspondante_id',
    interactionType: 'matching'
  },
  blocquestionassociationaudioaudio: {
    blockName: 'bloc_association_audio_audio',
    questionsBlockName: 'bloc_question_association_audio_audio_reponses',
    answersBlockName: 'reponse_question_association_audio_audio_reponses',
    answerName: 'bloc_question_association_audio_audio_reponse',
    questionName: 'bloc_question_association_audio_audio_audio_url',
    answerIdName: 'bloc_question_association_audio_audio_reponse_id',
    associatedAnswerIdName: 'bloc_question_association_audio_audio_reponse_correspondante_id',
    interactionType: 'matching'
  },
  audiomcq: {
    interactionType: 'choice',
    answersBlockName: 'audio_mcq_responses',
    answerIdName: 'audio_mcq_option_id',
    answerName: 'audio_mcq_option'
  }
};
